html,
body {
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  background-color: var(--chakra-colors-background-default);
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--chakra-colors-background-default);
  margin: 0 auto;
  max-width: 1500px;
}

iframe#launcher {
  display: none; /* Hide the Zendesk widget launcher */
}

*:focus {
  outline: none;
}

*:not(svg),
*:not(svg)::after,
*:not(svg)::before {
  margin: 0;
  padding: 0;
}

*::selection {
  background: var(--chakra-colors-background-primary);
  color: var(--chakra-colors-text-onContrast);
}

* {
  -webkit-font-smoothing: antialiased;
}
